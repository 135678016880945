import { Carousel } from '@v2/components';
import { getPopularArtists } from '@v2/services/getTopSellingEvents';
import { ArtistItem } from './ArtistItem';

export const TopArtists = async () => {
  const topArtists = await getPopularArtists({
    withVenueImages: true,
    perPage: 50,
  });

  return (
    <div>
      <h1 className="mb-6 text-lg font-bold md:text-2xl">Top Artists</h1>
      <Carousel>
        {topArtists.map(({ ...rest }) => (
          <ArtistItem {...rest} key={rest.id} />
        ))}
      </Carousel>
    </div>
  );
};
