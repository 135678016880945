'use client';
import { v2Links } from '@utils/navigation/links';
import Image from 'next/image';
import Link from 'next/link';

type Props = {};

export const ArtistItem = (props: any) => {
  console.log({ props });
  const { id, name, url, category, performances } = props;

  const artistSlug = performances.find((perf) => perf.primary)?.performer.slug;
  const categorySlug = category.slug;
  const categoryParentId = category.parent?.id;

  console.log({ categoryParentId });

  if (!url) return null;
  const randomNumber = Math.floor(Math.random() * 11) + 1;
  const imagePath = `/concerts/rsc-${randomNumber}.jpg`;
  return (
    <Link
      // href={`${v2Links.buy}?eventId=${id}`}
      href={`/events/${categorySlug}/${artistSlug}?parentId=${categoryParentId}`}
      className="flex max-h-[214px] flex-shrink-0 flex-grow-0 basis-60 flex-col"
    >
      <div className="mb-3 h-40 w-full flex-shrink-0 overflow-hidden rounded-lg">
        <Image
          src={imagePath}
          alt={name}
          width="0"
          height="0"
          sizes="246px"
          loading="lazy"
          className="h-[160px] w-[246px] rounded-lg object-cover object-center"
        />
      </div>
      <div className="w-full max-w-64">
        <h2 className="mb-2 text-sm font-semibold md:text-base">{name}</h2>
      </div>
    </Link>
  );
};
