import { USER_ROLE } from '@utils/auth/roles';
import { AlertToast, CheckCircle } from '@v2/ui';
import { useSession } from 'next-auth/react';
import toast from 'react-hot-toast';

const VISIBLE_COMPONENT = [
  USER_ROLE.affiliate,
  USER_ROLE.agencyMember,
  USER_ROLE.affiliate,
  USER_ROLE.individualAgent,
];

type CopyButtonOptions = {
  baseUrl?: string;
  eventId?: string;
  eventLink?: string;
};

export function useAffiliateLink() {
  const { data: session, status } = useSession();

  if (status === 'loading') {
    return { isLoading: true, link: null, onCopyButtonClick: null };
  }

  if (
    !session?.user?.affiliationId ||
    !VISIBLE_COMPONENT.includes(session?.user.role)
  ) {
    return { isLoading: false, link: null, onCopyButtonClick: null };
  }

  const onCopyButtonClick = ({
    baseUrl,
    eventId,
    eventLink,
  }: CopyButtonOptions = {}) => {
    // Use the current origin for relative paths
    const defaultUrl = new URL(window.location.href); // Default to current URL
    const urlToUse = baseUrl
      ? new URL(baseUrl, window.location.origin) // Resolve relative path against the current origin
      : defaultUrl;

    // Add optional parameters if provided
    if (eventId) urlToUse.searchParams.set('eventId', eventId);
    if (eventLink) urlToUse.searchParams.set('eventLink', eventLink);
    urlToUse.searchParams.set('af', session?.user.affiliationId || '');

    const textToCopy = urlToUse.toString();

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.custom(
          (t) => (
            <AlertToast
              {...t}
              title="Success"
              content={'Link has been copied to clipboard!'}
              icon={<CheckCircle />}
            />
          ),
          { position: 'top-right' }
        );
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return {
    isLoading: false,
    link: `?af=${session?.user.affiliationId}`,
    onCopyButtonClick,
  };
}
